(function($, window, document) {

    var canBeLoaded = true;

    window.ajax = {

        setup: function() {
            this.projectListTrigger();
        },
        projectListTrigger: function() {

            $('.load-more-projects').on('click', function(e) {
                window.ajax.projectList();
            });
        },
        
        projectList: function() {

            var data = {
                page  : ajaxVars.current_page,
                ppp :  maxPage.ppp,
                post_type : 'revive-project',
                action : 'more_post_ajax'

            }

        	if (canBeLoaded == true) {

                var $posts_container = $('.projects-list--items');

                if ( data ) {
                    $.ajax({
                        url : ajaxVars.ajaxurl,
                        data : data,
                        type : 'POST',
                        beforeSend : function ( xhr ) {
                            $posts_container.addClass('loading');
                            
                            $('.load-more-projects').addClass('loading');
                        },
                        success : function( data ){
                            
                            if( data ) { 
                                $posts_container.removeClass('loading');
                                // append new data to product container
                                $(data).appendTo($posts_container);
                                
                                // reenable loading
                                canBeLoaded = true;

                                if ( maxPage.max == ajaxVars.current_page ) {
                                    $('.projects-list--load-more').remove();
                                }

                                $('.load-more-projects').removeClass('loading');

                                ajaxVars.current_page++;
                                
                            }

                        }

                    });
                }
            }
        }

        
    };
})(window.jQuery, window, document); // End of jQuery wrapper
