(function($, window, document) {
    // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        setup: function() {
            this.initAOS();
            this.mobileMenu();
            this.scrollToAnchorLinks();
            this.pageLoaded();
            this.wrapWpButtonContentInSpan();
            this.shrinkHeaderOnScroll();
            this.detectStickyMenu();
        },

        shrinkHeaderOnScroll: function() {
            var $threshold = 40,
                $window = $(window),
                $height = $window.scrollTop();

            $window.scroll(function() {
                $scroll_position = $window.scrollTop();
                if ($scroll_position > $threshold) {
                    $("body").addClass("shrink");
                } else {
                    $("body").removeClass("shrink");
                }
            });

            // if the scroll position is greater than 0, add the shrink class. This handles a refresh in the middle of the page.
            if ($height > 0) {
                $("body").addClass("shrink");
            }

        },

        wrapWpButtonContentInSpan: function() {

            $('.wp-block-button a').wrapInner('<span></span>')
            
        },

        pageLoaded: function() {
            //$(window).load(function() {
            $(document).ready(function() {
                $('body').addClass('js-loaded');
            });
        },
        /**
         *  Init AOS.js
         *  @link https://michalsnik.github.io/aos/
         */
        initAOS: function() {
            AOS.init();
        },

        /**
         * Loads the social media "share" links into a new, small window
         */
        shareFunctionality: function() {
            $(".share-post a.social-link").click(function(e) {
                e.preventDefault();
                window.open(
                    $(this).attr("href"),
                    "fbShareWindow",
                    "height=450, width=550, top=" +
                        ($(window).height() / 2 - 275) +
                        ", left=" +
                        ($(window).width() / 2 - 225) +
                        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
                );
                return false;
            });
        },

        /**
         * Mobile Menu functionality
         */
        mobileMenu: function() {
            $(".menu-trigger-container").on("click", function(e) {
                if ($("body").hasClass("menu-open")) {
                    $("body").removeClass("menu-open");
                } else {
                    $("body").addClass("menu-open");
                }
            });

             // Append the "overview" item for menu items that have children
            $('.menus.mobile .menu-item-has-children > a').each(function() {
                href = $(this).attr('href');
                $(this).next().prepend('<li class="menu-item menu-overview"><a href="'+href+'">Overview</a></li>');
            });

            // reveal the mobile mega menu
            $('li.has-mega-menu > span, li.has-mega-menu > a').on('click', function(e) {
                e.preventDefault();
                $(this).toggleClass('open');
                $('.menus.mobile .main-menu--mega-menu').slideToggle();
            });
        },

        /**
         * Scroll to anchor links
         */
        scrollToAnchorLinks: function() {
            $(".wrap a[href^='#']:not(.noscroll)").on("click", function(e) {
                e.preventDefault();
                href = $(this).attr("href");
                target = $(href);
                $offset = target.data('offset');

                if ($(this).hasClass('use-data-offset')) {
                    $offset = $(this).data('offset');                    
                }

                if (!$offset) {
                    if ($(window).width() < 981) {
                        $offset = 95;
                    } else {
                        $offset = 120;
                    }
                }

                if (href.length > 1) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - $offset
                        },
                        500
                    );
                }
            });
        },

        scrollToTop: function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        },
        detectStickyMenu: function() {
            const stickyElm = document.querySelector('header.site-header')

            const observer = new IntersectionObserver( 
              ([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1),
              {threshold: [1]}
            );

            observer.observe(stickyElm)
        }

        
    };
})(window.jQuery, window, document); // End of jQuery wrapper
