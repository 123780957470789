(function($, window, document) {
    // jQuery wrapper
    
    // Page Functions
    window.animations = {

        setup: function() {
            this.reviveBanners();
            this.swapBannerPhrases();
            this.setPhraseWrapperHeight();
            this.phraseWrapperResize();
            this.addAOSAttributes();
            this.animateColumns();
        },

        animateColumns: function() {
            $('.animate-columns .wp-block-column').each(function(index) {
                delay = 150 * index;
                $(this).attr('data-aos-duration', '1000');
                $(this).attr('data-aos', 'fade-up');
                $(this).attr('data-aos-delay', delay);
            });

            
        },

        addAOSAttributes: function() {
            $('.animate-text h1').attr('data-aos', 'fade-up');
            $('.animate-text h1').attr('data-aos-duration', '1000');
        },

        phraseWrapperResize: function() {
            window.addEventListener('resize', function(event){
                console.log('resize');
                window.animations.setPhraseWrapperHeight()
            }, true);
        },

        setPhraseWrapperHeight: function() {
            var wrap = $('.extra-words-wrap'),
                el = $('.extra-words-wrap h1.active'),
                elHeight = el.height();

                wrap.height(elHeight);
        },

        reviveBanners: function() {
            let split = new SplitType(document.querySelectorAll('.hero--content h1, .hero--content h2, .hero--content p, .animate-text h1'), {
			  split: 'words, lines'
			});
        },

        swapBannerPhrases() {
            setInterval(window.animations.phrasesTransition, 2500);
        },



        phrasesTransition() {

            var phrases = $('.hero--content h1.extra'),
                phraseCount = $('.hero--content h1.extra').length,
                active = $('.hero--content h1.extra.active'),
                index = active.data('index'),
                next = index + 1;

            if (parseInt(index) == parseInt(phraseCount)) {
                next = 1;
            }
            
            phrases.removeClass('out');
            phrases.removeClass('active');
            $('h1.extra[data-index="' + next + '"]').addClass('active');
            $('h1.extra[data-index="' + index + '"]').addClass('out');


        }

        
    };
})(window.jQuery, window, document); // End of jQuery wrapper
