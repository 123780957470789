(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.sliders = {

        setup: function() {
            this.genericSlider();
        },
    	/**
    	 * Global load more button functionality
    	 */
        initSlider: function ($el, $args){
        	$el.slick($args);
        },

        genericSlider: function() {

            $el = $('.js--generic-slider');

            $el.each(function() {

                $arrows         = $(this).data('slick-arrows');
                $dots           = $(this).data('slick-dots');
                $autoplay       = $(this).data('slick-autoplay');
                $autoplay_speed = $(this).data('slick-autoplayspeed');
                $fade           = $(this).data('slick-fade');
                $infinite       = $(this).data('slick-infinite');
                $pauseOnHover   = $(this).data('slick-pause');
                $adaptiveHeight = $(this).data('slick-adaptive');

                $args = {       
                    arrows:        $arrows       == true ? true : false,
                    dots:          $dots         == true ? true : false,
                    infinite:      $infinite     == true ? true : false,
                    autoplay:      $autoplay     == true ? true : false,
                    pauseOnHover:  $pauseOnHover == true ? true : false,
                    fade:          $fade         == true ? true : false,
                    autoplaySpeed: $autoplay_speed,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: $adaptiveHeight == false ? false : true,
                    speed:500,
                    appendArrows: $(this).next(".g--custom-slider-controls"),
                    appendDots: $(this).next(".g--custom-slider-controls"),
                    prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon-half-arrow-left"></i><span>PREV</span></button>',
                    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon-half-arrow"></i><span>NEXT</span></button>',
                    customPaging : function(slider, i) {
                        return '<div class="slick-button"></div>';
                    },
                };

                
                window.sliders.initSlider( $el, $args );
            });
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

/*

slidesToShow: 4,
slidesToScroll: 4,
responsive: [
{
  breakpoint: 1024,
  settings: {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    dots: true
  }
},
{
  breakpoint: 600,
  settings: {
    slidesToShow: 2,
    slidesToScroll: 2
  }
},
{
  breakpoint: 480,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1
  }
}
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object
]


 */